const Utils = {
  getFormattedDate: (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return month + "." + day + "." + year;
  },
  emailValidator: (_, value) => {
    return new Promise((resolve, reject) => {
      if (!value || /\S+@\S+\.\S+/.test(value)) {
        return resolve();
      } else {
        reject("please enter valid email");
      }
    });
  },
  zipValidator: (_, value) => {
    return new Promise((resolve, reject) => {
      if (!value || /^\d+$/.test(value)) {
        return resolve();
      } else {
        reject("zip code should not contain letters");
      }
    });
  },
  decimalCount(number) {
    // Convert to String
    const numberAsString = number.toString();
    // String Contains Decimal
    if (numberAsString.includes(".")) {
      return numberAsString.split(".")[1].length;
    }
    // String Does Not Contain Decimal
    return 0;
  },
  getFormattedDateandTime: (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    let hours = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");
    let seconds = date.getSeconds().toString().padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} +00:00`;
  },
  getFormattedDateTime: (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    let hours = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");
    let seconds = date.getSeconds().toString().padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  },
  getMetricFormattedDate: (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  },
  getFormatedDatePromoCode: (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return year + "-" + month + "-" + day;
  },
  convertToAmPm: (time) => {
    time = time
      ?.toString()
      ?.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time?.length > 1) {
      // If time format correct
      time = time?.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time?.join("");
  },
  roundNumber: (number) => {
    return new Intl.NumberFormat(
      "en-US",
      { style: "currency", currency: "USD" },
      { minimumFractionDigits: 2, maximumFractionDigits: 2, currency: "USD" }
    )
      .format(number)
      .replace(/,/g, " ");
  },
  roundNumberWithoutCurrency: (number) => {
    return new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .replace(/,/g, " ");
  },
  quantityValidator: (_, value) => {
    return new Promise((resolve, reject) => {
      if (value === undefined) {
        reject("Please enter a quantity.");
      } else if (!/^\d+$/.test(value)) {
        reject("Quantity must be a whole number (integer).");
      } else if (parseInt(value, 10) <= 0) {
        reject("Quantity must be greater than 0.");
      } else {
        resolve();
      }
    });
  },
  debounce: (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  },
  debouncePrice: (func, delay) => {
    let timeoutId;
    return function (index, value) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(index, value);
      }, delay);
    };
  },
  numberValidator: (_, value) => {
    return new Promise((resolve, reject) => {
      if (
        value !== "" &&
        value !== undefined &&
        !/^(\d+(\.\d+)?|-\d+(\.\d+)?)$/.test(value)
      ) {
        reject("Input must be a numeric value");
      } else if (value !== "" && parseFloat(value) < 0) {
        reject("Input must be 0 or a positive number.");
      } else {
        resolve();
      }
    });
  },
  integerValidator: (_, value) => {
    return new Promise((resolve, reject) => {
      if (
        value !== "" &&
        value !== undefined &&
        !/^-?\d+$/.test(value) // Regex to allow only integers (positive and negative)
      ) {
        reject("Input must be an integer value.");
      } else if (value !== "" && parseInt(value) < 0) {
        reject("Input must be 0 or a positive integer.");
      } else {
        resolve();
      }
    });
  },
  checkFiledsIdentity: (arr, isCustomTax) => {
    return (_, value) => {
      return new Promise((resolve, reject) => {
        if (!value) {
          reject(isCustomTax ? "Missing Tax name" : "Missing name");
        } else if (
          arr?.find(
            (el) =>
              el?.name?.toLowerCase()?.replace(/\s/g, "") ===
              value?.toLowerCase()?.replace(/\s/g, "")
          )
        ) {
          reject(`There is also a field with same name (${value}).`);
        }
      });
    };
  },
  getFormatedDate: () => {
    var d = new Date();
    return (
      [d.getMonth() + 1, d.getDate(), d.getFullYear()].join("/") +
      " " +
      [d.getHours(), d.getMinutes(), d.getSeconds()].join(":")
    );
  },
  decimalToFraction: (decimal) => {
    if (typeof decimal === "string") {
      decimal = +decimal;
    }

    if (Number.isInteger(decimal)) {
      return decimal.toString();
    }

    const precision = Math.pow(10, decimal.toString().split(".")[1].length);

    const gcd = (a, b) => (b ? gcd(b, a % b) : a);
    const divisor = gcd(precision, decimal * precision);

    const numerator = (decimal * precision) / divisor;
    const denominator = precision / divisor;

    return `${numerator}/${denominator}`;
  },
  joinDate(date) {
    function format() {
      let formatter = new Intl.DateTimeFormat("en-us");
      return formatter.format(date);
    }
    return format();
  },
  getUniqueListBy(arr, key) {
    return [
      ...new Map(
        arr.map((item, index) => [item[key] || index + "index", item])
      ).values(),
    ];
  },
  filterOption: (input, option) =>
    (option?.label ?? "")?.toLowerCase()?.includes(input?.toLowerCase()),
  statusNameMapping: (status) => {
    switch (status) {
      case "IN_PROGRESS":
        return "In Progress";
      case "READY_FOR_ATTAINMENT":
        return "Finish preparing";
      case "COMPLETE":
        return "Complete";
      case "CANCELED_CUSTOMER":
        return "Canceled";
      default:
        return "";
    }
  },
  metrcStatusNameMapping: (status) => {
    switch (status) {
      case "PENDING":
        return "Pending";
      case "ERROR_SUBMISSIONS":
        return "Error Submissions";
      case "COMPLETE":
        return "Complete";
      case "NOT_SUBMITTED":
        return "Not Submitted";
      case "ACTIVE":
        return "Active";
      case "INACTIVE":
        return "Inactive";
      case "SUCCESSFUL TAGS":
        return "Successful Tags";
      case "SKIPPED TAGS":
        return "Skipped Tags";
      case "FAILED TAGS":
        return "Failed Tags";
      case "PURCHASE ORDERS":
        return "Purchase Orders";
      case "RECEIVING":
        return "Receiving";
      case "BILLS":
        return "Bills";
      case "CANCELED":
        return "Canceled";
      case "ACCEPTED":
        return "Accepted";
      default:
        return "";
    }
  },
  taxNameMapping: (key) => {
    switch (key) {
      case "total_subtotal":
        return "Total SubTotal";
      case "total_sales_tax":
        return "Total Sales Tax";
      case "total_excise_tax":
        return "Total Excise Tax";
      case "total_city_m_tax":
        return "Total City M Tax";
      case "total_city_r_tax":
        return "Total City R Tax";
      case "total_grand_total":
        return "Grand Total";
      default:
        return "";
    }
  },
  menuNameMapping: (name) => {
    switch (name) {
      case "weedmaps":
        return "WeedMaps";
      case "wordpress":
        return "Wordpress";
      case "leafly":
        return "Leafly";
      case "canasale":
        return "CanaSale";
      case "shop":
        return "Shop";
      default:
        return "";
    }
  },
  isValidNumber: (value) => {
    if (
      typeof value === "boolean" ||
      value === null ||
      value === "" ||
      Array?.isArray(value) ||
      typeof value === "object" ||
      value === undefined
    ) {
      return false;
    }
    // Convert the value to a number
    const num = Number(value);
    if (Object.is(num, -0) || Object.is(num, 0) || value.toString() === ".0") {
      return false;
    }
    // Check if it's a valid number and matches the original value (as string or number)
    return !isNaN(num) && isFinite(num);
  },
};

export default Utils;
